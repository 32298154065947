import React from "react";
import { Card, Input, Button, Modal, Select, message, Popconfirm } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory/*, { selectFilter, textFilter } */ from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, Form, FormGroup, Label, Spinner, Table } from 'reactstrap';
import Media from 'react-media';


import { services } from '../../../services/services';

import { authenticationService } from "../../../services/authentication-service";
import { use_logiciel } from '../../../env'
import { numberWithCommas } from '../../../helpers/index';



const currentUser = authenticationService.currentUserValue;


const RemotePagination = ({ data, page, sizePerPage, handleDeleteArticle, cancelDelete, onTableChange, totalSize, handleUpdateOpen, handleView, handleDelete, handleModalDeleteItem, isMobile }) => {
    const actions = (cell, row, rowIndex, formatExtraData) => {
        // console.log('sortie v', row)
        return (


            < div
                style={{ textAlign: "center", justifyContent: 'center' }} className="d-flex">
                <Button
                    type="primary"
                    //fullWidth
                    variant="contained"
                    color="primary"
                    size="small"
                    //className="btn btn-success btn-sm"
                    onClick={() => handleView(row)}
                >
                    <i className="icon icon-custom-view" />
                </Button>
                <Button
                    hidden={use_logiciel === 'NO' ? false : true}
                    type="danger"
                    //fullWidth
                    variant="contained"
                    color="danger"
                    size="small"

                    //className="btn btn-success btn-sm"
                    onClick={() => handleUpdateOpen(row)}
                >
                    <i className="icon icon-edit" />
                </Button>
                {/*<Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Article?" onConfirm={handleDeleteArticle} onCancel={cancelDelete} okText="Oui"
                    cancelText="Non">
                    <Button
                        hidden={use_logiciel === 'NO' ? false : true}
                        type="danger"
                        //fullWidth
                        variant="contained"
                        color="primary"
                        size="small"
                        //className="btn btn-success btn-sm"
                        onClick={() => handleModalDeleteItem(row)}
                    >
                        <i className="icon icon-alert-new" />
                    </Button>

                </Popconfirm>
                */}

            </div>

        );
    }



    const columns = [
        {
            dataField: 'codPro',
            text: ' Code'
        },
        /*{
          dataField: 'codInterne',
          text: ' Référence '
        },*/
        {
            dataField: 'designation',
            text: ' Désignation '
        },
        /*{
          dataField: 'qteStock',
          text: ' Qté stocck'
        },
        {
          dataField: 'idemBal',
          text: ' Emballage'
        },*/
        {
            dataField: 'categorie.libCat',
            text: 'Catégorie '
        },
        {
            dataField: 'prix',
            text: 'Prix',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div
                        style={{ textAlign: "center" }} className="d-flex">
                        {parseFloat(row && row.prix && row.prix.prixUnitaire)}
                    </div>
                );
            },
        },


        // (currentUser.agence.enCours===true && currentUser.slug==="ADMIN_ROLE"  ) &&
        {
            dataField: 'actions',
            text: 'Action',
            headerAlign: 'center',
            sort: true,
            headerAttrs: { width: 50 },
            align: 'center',
            hidden: ((currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "AGENT_ROLE") || (currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "ADMIN_ROLE")) ? true : false,
            formatter: actions,
            attrs: { width: 50, className: "actions" }


        },

    ];

    const columnsArticlesPhone = [

        {
            dataField: 'designation',
            text: ' Désignation'
        },

        // (currentUser.agence.enCours===true && currentUser.slug==="ADMIN_ROLE"  ) &&
        {
            dataField: 'actions',
            text: 'Action',
            headerAlign: 'center',
            sort: true,
            headerAttrs: { width: 50 },
            align: 'center',
            hidden: ((currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "AGENT_ROLE") || (currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "ADMIN_ROLE")) ? true : false,
            formatter: actions,
            attrs: { width: 50, className: "actions" }


        },

    ];


    return (

        <div>
            <PaginationProvider
                pagination={
                    paginationFactory({
                        custom: true,
                        page,
                        sizePerPage,
                        totalSize,
                        withFirstAndLast: true,
                        prePageText: isMobile? '<' : 'Préccédent',
                        nextPageText: isMobile? '>' : 'Suivant',
                        hidePageListOnlyOnePage: true,
                    })
                }
            >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <div>

                            <BootstrapTable
                                remote
                                keyField="id"
                                data={data}
                                columns={isMobile ? columnsArticlesPhone : columns}
                                onTableChange={onTableChange}
                                {...paginationTableProps}
                                filter={filterFactory()}
                                bordered={true}
                                striped
                                hover
                                condensed
                                wrapperClasses="table-responsive"
                            />
                            <Row>
                                <Col>
                                    <div className="mt-1 d-flex justify-content-start">
                                        <h5 className='fw-bold' style={{ color: 'blue', fontWeight: 'bold' }}>TOTAL: {totalSize}</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="mt-1 d-flex justify-content-end">
                                        <PaginationListStandalone
                                            {...paginationProps}
                                        />
                                    </div>
                                </Col>
                            </Row>


                        </div>
                    )
                }
            </PaginationProvider>

        </div>
    );
}


class Prix extends React.Component {
    state = {
        page: 1,
        sizePerPage: 10,
        currentIndex: 0,

        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,
        isAddCategorieModalVisible: false,
        loading: false,
        articles: [],
        categories: [],
        tva: [],
        usuel: "1",
        codTyp: "1",
        designation: '',
        codeCat: '',
        codInterne: '',
        puVente: 0,
        prixGrossiste: 0,
        prixDetaillant: 0,
        codTvaVente: '',
        idemBal: 1,

        reference: '',

        qteAlert: 0,
        stockMin: 0,
        taxeSpecifique: '',
        descriptionTs: '',
        codBarre: '',
        puAchat: '',
        especeUnite: '',
       // prixDetaillant: '',
       // prixGrossiste: '',

        waitingEndMsg: '',
        isLoading: true,
        search: '',
        agences: [],
    }


    handleTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        console.log('type,page,sizePerPage,currentIndex', type, page, sizePerPage, currentIndex)
        services.getArticlesPrice(sizePerPage, currentIndex, this.state.search, this.state.codCat)
            .then(articles => {
                this.setState({ articles: articles.data, totalSize: articles.recordsFiltered, page })

            })
    }

    handleSearch = (e) => {
        // console.log("SEARCH", e.target.value)
        this.setState({ search: e.target.value, sizePerPage: 10, currentIndex: 0, page: 1, waitingEndMsg: '', isLoading: true })
        services.getArticlesPrice(this.state.sizePerPage, this.state.currentIndex, e.target.value, this.state.codCat)
            .then(articles => {
                //console.log("articles SEARCH", articles.data)

                this.setState({ articles: articles.data, totalSize: articles.recordsFiltered })
                setTimeout(() => {

                    // console.log("real articles", factures)
                    if (!articles.data || !Array.isArray(articles.data)
                        || !articles.data.length > 0) {

                        this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune Classe pour cette recherche!`, isOpenDelete: false, })
                    }
                }, 500)
            })
    }


    openCategorieAdd = () => {
        this.setState({ isAddCategorieModalVisible: !this.state.isAddCategorieModalVisible })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleAddCategorie = () => {
        const {
            //codCat,
            libCat,

        } = this.state


        if (libCat === '') {
            message.warning('Veuillez préciser la Raison sociale', 5)
            return;
        }
        /* if (codCat === '') {
           message.warning('Veuillez préciser le code de la Catégorie', 5)
           return;
         }*/
        this.setState({ loading: true })
        let req = {
            //codCat,
            libCat: libCat.toUpperCase(),

        }
        services.addCategorie(req)
            .then(result => {
                // console.log(result)
                this.setState({
                    codCat: '',
                    loading: false,
                    libCat: '',

                    isAddCategorieModalVisible: !this.state.isAddCategorieModalVisible,

                })
                message.success('Categorie ajouter avec succès', 10)

                services.getCategories()
                    .then(categories => {
                        this.setState({ categories })
                    })

            })

            .catch((err) => {
                this.setState({ loading: false })
                message.error(err, 10)
            });
    }

    componentDidMount() {
        services.getArticlesPrice()
            .then(articles => {
                console.log('articles', articles)
                this.setState({ articles: articles.data, totalSize: articles.recordsTotal, isLoading: false })
                setTimeout(() => {

                    // console.log("real articles", factures)
                    if (!articles.data || !Array.isArray(articles.data)
                        || !articles.data.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucun Article actuellement !" })
                    }
                }, 4000)
            })

        services.getCategories()
            .then(categories => {
                //console.log('Roles', roles)
                this.setState({ categories })
            })

        services.getTva()
            .then(tva => {
                this.setState({ tva })
            })

        services.getAllAgences()
            .then(agences => {
                //console.log('agences', agences)
                this.setState({ agences })
            })

    }
    handleAddOpen = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            codPro: '',
            reference: '',
            designation: '',
            codeCat: '',
            codTyp: '1',
            idemBal: '',
            codTvaVente: '',
            taxeSpecifique: '',
            descriptionTs: '',
            codBarre: '',
            puAchat: '',
            especeUnite: '',
            usuel: '1',
            codInterne: '',
            puVente: '',
            prixDetaillant: '',
            prixGrossiste: '',
            loading: false,
            qteAlert: 0,
            stockMin: 0,

        });
    };

    handleCancelAdd = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            codPro: '',
            reference: '',
            designation: '',
            qteAlert: 0,
            stockMin: 0,
            codeCat: '',
            codTyp: '',
            idemBal: '',
            codTvaVente: '',
            taxeSpecifique: '',
            descriptionTs: '',
            codBarre: '',
            puAchat: '',
            especeUnite: '',
            usuel: '',
            codInterne: '',
            puVente: '',
            prixDetaillant: '',
            prixGrossiste: '',
        })
    }

    handleAddArticle = () => {
        const {
            //codPro,
            reference,
            designation,
            qteAlert,
            stockMin,
            codeCat,
            codTyp,
            idemBal,
            codTvaVente,
            taxeSpecifique,
            descriptionTs,
            codBarre,
            puAchat,
            especeUnite,
            usuel,
            codInterne,
            puVente,
            prixDetaillant,
            prixGrossiste,
        } = this.state


        if (designation === '') {
            message.warning('Veuillez préciser la Désignation', 5)
            return;
        }
        if (stockMin === '') {
            message.warning('Veuillez préciser le Stock Minimum', 5)
            return;
        }
        if (qteAlert === '') {
            message.warning('Veuillez préciser la Quantité Alerte', 5)
            return;
        }
        /*if (codInterne === '' || codInterne === null) {
          message.warning('Veuillez préciser la Référence', 5)
          return;
        }*/
        if (codeCat === '' || codeCat === null || codeCat === undefined) {
            message.warning('Veuillez sélectionner la Catégorie', 5)
            return;
        }

        if (codTvaVente === '' || codTvaVente === null || codTvaVente === undefined) {
            message.warning('Veuillez sélectionner la Tva', 5)
            return;
        }

        if (puVente === '' || puVente === null || puVente === undefined || puVente === 0) {
            message.warning('Veuillez préciser le prix de vente', 5)
            return;
        }

        if (puAchat === '' || puAchat === null || puAchat === undefined) {
            message.warning('Veuillez préciser le prix de vente', 5)
            return;
        }

        if (parseFloat(stockMin) > parseFloat(qteAlert)) {
            message.warning('La quantité Alerte ne peut être inférieur au Stock minimum', 5)
            return;
        }

        if (parseFloat(puAchat) > parseFloat(puVente)) {
            message.warning('Le PU Achat ne peut être supérieur au PU Vente', 5)
            return;
        }

        this.setState({ loading: true })

        let req = {
            // codPro,
            reference: reference,
            designation: designation.toUpperCase(),
            qteAlert,
            stockMin,
            codeCat,
            codTyp,
            idemBal,
            codTvaVente,
            tauxTs: taxeSpecifique,
            dtsTs: descriptionTs,
            codBarre,
            puAchat,
            especeUnite,
            usuel,
            codInterne: codInterne.toUpperCase(),
            puVente,
            prixDetaillant,
            prixGrossiste,
            //raisonSociale: raisonSociale.toUpperCase(),

        }
        services.addArticle(req)
            .then(result => {
                // console.log(result)
                this.setState({
                    codPro: '',
                    reference: '',
                    designation: '',
                    qteAlert: 0,
                    stockMin: 0,
                    codeCat: '',
                    codTyp: '',
                    idemBal: '',
                    codTvaVente: '',
                    taxeSpecifique: '',
                    descriptionTs: '',
                    codBarre: '',
                    puAchat: '',
                    especeUnite: '',
                    usuel: '',
                    codInterne: '',
                    puVente: '',
                    prixDetaillant: '',
                    prixGrossiste: '',
                    isAddModalVisible: !this.state.isAddModalVisible,
                    loading: false,

                })
                message.success('Article ajouter avec succès', 10)

                services.getArticlesPrice()
                    .then(articles => {
                        this.setState({ articles })
                    })

            })

            .catch((err) => {
                message.error(err, 10)
            });
    }

    handleUpdateArticle = () => {
        const {
            //idArticle,
            codPro,

            puVente,
            codAgence,
            prixDetaillant,
            prixGrossiste
        } = this.state

        // console.log('PUVENTE', puVente)

        this.setState({ isLoading: true })


        if (codAgence === '' || codAgence === null || codAgence === undefined) {
            message.warning('Veuillez sélectionner une Agence', 5)
            return;
        }

        if (puVente === '' || puVente === null || puVente === undefined || puVente === 0) {
            message.warning('Veuillez préciser un prix de vente', 5)
            return;
        }

        if (codPro === '' || codPro === null || codPro === undefined) {
            message.warning('Veuillez sélectionner un Article', 5)
            return;
        }



        let req = {
            //idArticle,
            codPro,

            puVente: puVente,
            codAgence,
            prixDetaillant,
            prixGrossiste,

        }
        services.updateArticlePrice(codPro, req)
            .then(result => {
                // console.log(result)
                this.setState({
                    idArticle: '',
                    codPro: '',
                    codAgence: '',
                    puVente: '',
                    prixDetaillant: 0,
                    prixGrossiste:0,

                    isUpdateModalVisible: !this.state.isUpdateModalVisible,

                })
                message.success('Article modifier avec succès', 10)

                services.getArticlesPrice(this.state.sizePerPage, this.state.currentIndex, this.state.search, this.state.codCat)
                    .then(articles => {
                        //console.log("articles SEARCH", articles.data)

                        this.setState({ articles: articles.data, totalSize: articles.recordsFiltered, isLoading: false })
                        setTimeout(() => {

                            // console.log("real articles", factures)
                            if (!articles.data || !Array.isArray(articles.data)
                                || !articles.data.length > 0) {

                                this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune Classe pour cette recherche!`, isOpenDelete: false, })
                            }
                        }, 500)
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
            });
    }

    handleDelete = (row) => {
        this.setState({
            codPro: row.codPro,

            designation: row.designation,
        })

    }

    handleDeleteArticle = () => {

        const { codPro } = this.state
        this.setState({ isLoading: true })

        services.deleteArticle(codPro)
            .then(del => {
                //console.log('delete user', del)
                message.success('Article supprimer avec succès', 10)

                services.getArticlesPrice(this.state.sizePerPage, this.state.currentIndex, this.state.search, this.state.codCat)
                    .then(articles => {
                        //console.log("articles SEARCH", articles.data)

                        this.setState({ articles: articles.data, totalSize: articles.recordsFiltered })
                        setTimeout(() => {

                            // console.log("real articles", factures)
                            if (!articles.data || !Array.isArray(articles.data)
                                || !articles.data.length > 0) {

                                this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune Classe pour cette recherche!`, isOpenDelete: false, })
                            }
                        }, 500)
                    })

            })
            .catch((err) => {
                //console.log(err)
                message.error(err, 10)
            });

    }

    handleUpdateOpen = (row) => {
        this.setState({
            codAgence: '',
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            idArticle: row.id,
            codPro: row.codPro,
            reference: row.ref,
            designation: row.designation,
            codeCat: row.categorie && row.categorie.codCat,

            qteAlert: parseFloat(row.qteAlert),
            stockMin: parseFloat(row.stockMin),

            codTyp: `${row.codTyp}`,
            idemBal: row.idemBal,  //Emballage
            codTvaVente: row.codTvaVente,
            taxeSpecifique: row.tauxTs,
            descriptionTs: row.dtsTs,
            codBarre: row.codBarre,
            puAchat: parseFloat(row.puAchat),
            especeUnite: row.especeUnite,
            usuel: `${row.usuel}`,
            codInterne: row.codInterne,
            //PAS DISPONIBLE DANS FICHIER ARTICLE
            puVente: parseFloat(row && row.prix && row.prix.prixUnitaire) || 0,
            prixDetaillant: 0,
            prixGrossiste: 0,
        })

    }
    handleCancelUpdate = () => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            codPro: '',
            reference: '',
            designation: '',
            qteAlert: 0,
            stockMin: 0,
            codeCat: '',
            codTyp: '',
            idemBal: '',
            codTvaVente: '',
            taxeSpecifique: '',
            descriptionTs: '',
            codBarre: '',
            puAchat: '',
            especeUnite: '',
            usuel: '',
            codInterne: '',
            puVente: '',
            prixDetaillant: '',
            prixGrossiste: '',
        })
    }


    handleView = (row) => {
        // console.log('ROW', row)
        this.setState({
            isModalVisible: !this.state.isModalVisible,
            codPro: row.codPro,
            reference: row.ref,
            designation: row.designation,
            codeCat: row.categorie && row.categorie.codCat,

            qteAlert: parseFloat(row.qteAlert),
            stockMin: parseFloat(row.stockMin),

            codTyp: `${row.codTyp}`,
            idemBal: row.idemBal,  //Emballage
            codTvaVente: row.codTvaVente,
            taxeSpecifique: row.tauxTs,
            descriptionTs: row.dtsTs,
            codBarre: row.codBarre,
            puAchat: parseFloat(row.puAchat),
            especeUnite: row.especeUnite,
            usuel: `${row.usuel}`,
            codInterne: row.codInterne,
            //PAS DISPONIBLE DANS FICHIER ARTICLE
            puVente: parseFloat(row && row.prix && row.prix.prixUnitaire) || 0,
            prixDetaillant: row.prixDetaillant,
            prixGrossiste: row.prixGrossiste,
            article: row,


        });
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            codPro: '',
            reference: '',
            designation: '',
            qteAlert: 0,
            stockMin: 0,
            codeCat: '',
            codTyp: '',
            idemBal: '',
            codTvaVente: '',
            taxeSpecifique: '',
            descriptionTs: '',
            codBarre: '',
            puAchat: '',
            especeUnite: '',
            usuel: '',
            codInterne: '',
            puVente: '',
            prixDetaillant: '',
            prixGrossiste: '',
        });
    }

    handleChangeCategorie = (value) => {
        this.setState({ codeCat: value })
        // console.log(`selected ${value} ${this.state.codeCat}`);
    }
    handleChangeAgence = (value) => {
        //console.log('AGENCE ', value)

        this.setState({ codAgence: value })
        const codAgence = value
        // console.log(`selected ${value} ${this.state.codeCat}`);
        services.getOneAgencePrice(this.state.codPro, codAgence)
                    .then(prix => {
                        //console.log('PRIX AGENCE ', prix)
                        this.setState({ puVente: prix.prixUnitaire ? prix.prixUnitaire : this.state.puVente, prixDetaillant: prix.prixDetaillant ? prix.prixDetaillant: this.state.prixDetaillant, prixGrossiste: prix.prixGrossiste ? prix.prixGrossiste: this.state.prixGrossiste  })
                    })
    }

    handleBlur = () => {
        //  console.log('blur');
    }

    handleFocus = () => {
        // console.log('focus');
    }

    handleChangeType = (value) => {
        this.setState({ codTyp: value })
    }

    handleChangeTvaVente = (value) => {
        this.setState({ codTvaVente: value })
    }

    handleChangeUsuel = (value) => {
        this.setState({ usuel: value })
    }

    render() {
        const {
            sizePerPage,
            page,
            totalSize,

            isModalVisible,
            loading,
            codPro,

            designation,
            //qteAlert,
            //stockMin,
            codeCat,
            //codTyp,

            //codTvaVente,

            //codBarre,
            //puAchat,

            //usuel,

            puVente,
            prixDetaillant,
            prixGrossiste,

            articles,
            categories,
            //tva,

            //codCat,
            libCat,
            isAddModalVisible,
            isUpdateModalVisible,
            isAddCategorieModalVisible,

            isLoading,
            waitingEndMsg,

            search,
            agences,
            codAgence,
            article,

        } = this.state

        const InputGroup = Input.Group;
        const Option = Select.Option;

        const { SearchBar } = Search;

        const columnsArticles = [
            /*{
            dataField: 'id',
            text: ' ID'
          },*/
            {
                dataField: 'codPro',
                text: ' Code'
            },
            /*{
              dataField: 'codInterne',
              text: ' Référence '
            },*/
            {
                dataField: 'designation',
                text: ' Désignation '
            },
            /*{
              dataField: 'qteStock',
              text: ' Qté stocck'
            },
            {
              dataField: 'idemBal',
              text: ' Emballage'
            },*/
            {
                dataField: 'categorie.libCat',
                text: 'Catégorie '
            },
            {
                dataField: 'prix',
                text: 'Prix',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {parseFloat(row && row.prix && row.prix.prixUnitaire)}
                        </div>
                    );
                },
            },


            // (currentUser.agence.enCours===true && currentUser.slug==="ADMIN_ROLE"  ) &&
            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                hidden: ((currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "AGENT_ROLE") || (currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "ADMIN_ROLE")) ? true : false,
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                            <Button
                                hidden={use_logiciel === 'NO' ? false : true}
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="danger"
                                size="small"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleUpdateOpen(row)}
                            >
                                <i className="icon icon-edit" />
                            </Button>
                            {/*<Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Article?" onConfirm={this.handleDeleteArticle} onCancel={this.cancelDelete} okText="Oui"
                                cancelText="Non">
                                <Button
                                    hidden={use_logiciel === 'NO' ? false : true}
                                    type="danger"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDelete(row)}
                                >
                                    <i className="icon icon-alert-new" />
                                </Button>

                            </Popconfirm>
                    */}

                        </div>
                    );
                },


            }

        ];


        const columnsArticlesPhone = [

            {
                dataField: 'designation',
                text: ' Désignation'
            },

            // (currentUser.agence.enCours===true && currentUser.slug==="ADMIN_ROLE"  ) &&
            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                hidden: ((currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "AGENT_ROLE") || (currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "ADMIN_ROLE")) ? true : false,
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="danger"
                                size="small"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleUpdateOpen(row)}
                            >
                                <i className="icon icon-edit" />
                            </Button>
                           {/* <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Article?" onConfirm={this.handleDeleteArticle} onCancel={this.cancelDelete} okText="Oui"
                                cancelText="Non">
                                <Button
                                    type="danger"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDelete(row)}
                                >
                                    <i className="icon icon-alert-new" />
                                </Button>

                            </Popconfirm>
                    */}
                        </div>
                    );
                },


            }

        ];

        const defaultSorted = [{
            dataField: 'designation',
            order: 'asc'
        }];



        return (
            <div>
                <Card title="Liste des Prix des Articles">

                    <Media query="(min-width: 600px)" render={() =>
                    (
                        <ToolkitProvider
                            keyField="id"
                            data={articles}
                            columns={columnsArticles}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >

                                            <Col sm={12} xxl={6} md={6} xl={6} lg={6} >
                                                <div className="search-box">

                                                    <Input
                                                        type="text"
                                                        className="form-control search bg-light mb-3"
                                                        placeholder='Rechercher un Article'
                                                        onChange={this.handleSearch}
                                                        name="search"
                                                        value={search}
                                                        style={{ maxWidth: 750, width: '100%', margin: 'auto', display: 'flex', borderRadius: 50 }}

                                                    />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </Col>
                                            {/*(currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") && use_logiciel === 'NO' &&
                                                <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>

                                */}
                                        </Row>

                                        <RemotePagination
                                            data={articles}
                                            page={page}
                                            sizePerPage={sizePerPage}
                                            totalSize={totalSize}
                                            onTableChange={this.handleTableChange}
                                            handleModalDeleteItem={this.handleDelete}
                                            handleUpdateOpen={this.handleUpdateOpen}
                                            handleToggle={this.handleToggle}
                                            handleView={this.handleView}
                                            isMobile={false}

                                        />

                                        {/*<BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                            hover
                                            condensed
                                            headerWrapperClasses="foo"
                                            noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                                        //filter={filterFactory()}
                                        />
                                        */}

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={articles}
                            columns={columnsArticlesPhone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >

                                            <Col sm={12} xxl={6} md={6} xl={6} lg={6} >
                                                <div className="search-box">

                                                    <Input
                                                        type="text"
                                                        className="form-control search bg-light mb-3"
                                                        placeholder='Rechercher un Article'
                                                        onChange={this.handleSearch}
                                                        name="search"
                                                        value={search}
                                                        style={{ maxWidth: 750, width: '100%', margin: 'auto', display: 'flex', borderRadius: 50 }}

                                                    />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </Col>
                                            {/*(currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") && use_logiciel === 'NO' &&
                                                <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>

                                */}
                                        </Row>

                                        <RemotePagination
                                            data={articles}
                                            page={page}
                                            sizePerPage={sizePerPage}
                                            totalSize={totalSize}
                                            onTableChange={this.handleTableChange}
                                            handleModalDeleteItem={this.handleDelete}
                                            handleUpdateOpen={this.handleUpdateOpen}
                                            handleToggle={this.handleToggle}
                                            handleView={this.handleView}
                                            isMobile={true}

                                        />

                                        {/*<BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                            hover
                                            condensed
                                            headerWrapperClasses="foo"
                                            noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                                        //filter={filterFactory()}
                                        />
                                        */}

                                    </div>

                                )
                            }
                        </ToolkitProvider>

                        
                       
                    )}
                    />
                </Card>


                <Modal
                    width={800}
                    visible={isAddModalVisible}
                    title="Ajouter un Article"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAdd}
                    footer={[
                        <Button type="primary" loading={loading} onClick={this.handleAddArticle}>
                            Envoyer
                        </Button>,
                        <Button type="danger" onClick={this.handleCancelAdd}>
                            Annuler
                        </Button>,

                        /*<Button
                         key="link"
                         href="https://google.com"
                         type="primary"
                         loading={loading}
                         onClick={this.handleOk}
                       >
                         Search on Google
                       </Button>,*/
                    ]}
                >
                    <Form>
                        <Row form>
                            {/*<Col md={6}>
                <FormGroup>
                  <Label for="codPro">Code Produit</Label>
                  <Input type="number" name="codPro" id="codPro" value={codPro} onChange={this.handleChange} placeholder="" />
                </FormGroup>
               </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="codInterne">Référence Article</Label>
                  <Input type="text" name="codInterne" value={codInterne} id="codInterne" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col> */}

                            <Col md={12}>
                                <FormGroup>
                                    <Label for="designation">Désignation</Label>
                                    <Input disabled type="text" name="designation" value={designation} onChange={this.handleChange} id="designation" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="codeCat">Catégorie</Label>
                                    <InputGroup compact>
                                        <Select
                                            value={codeCat}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '85%' }}
                                            placeholder="Selectionner Catégorie"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeCategorie}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                categories && categories.length ?
                                                    categories.map((categorie, k) => {
                                                        return <Option key={k} value={categorie.codCat}>{categorie.libCat}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button onClick={this.openCategorieAdd} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>

                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="puVente">Prix Vente</Label>
                                    <Input type="number" name="puVente" id="puVente" value={puVente} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>
                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isUpdateModalVisible}
                    title="Modifier le Prix de l'Article"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelUpdate}
                    footer={[
                        <Button key="link" type="primary" loading={loading} onClick={this.handleUpdateArticle}>
                            Modifier
                        </Button>,
                        <Button key="link2" type="danger" onClick={this.handleCancelUpdate}>
                            Annuler
                        </Button>,

                        /*<Button
                         key="link"
                         href="https://google.com"
                         type="primary"
                         loading={loading}
                         onClick={this.handleOk}
                       >
                         Search on Google
                       </Button>,*/
                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="codPro">Code Produit</Label>
                                    <Input disabled type="number" name="codPro" id="codPro" value={codPro} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={9}>
                                <FormGroup>
                                    <Label for="designation">Désignation</Label>
                                    <Input disabled type="text" name="designation" value={designation} onChange={this.handleChange} id="designation" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="codAgence">Agence</Label>
                                    <InputGroup compact>
                                        <Select
                                            value={codAgence}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '85%' }}
                                            placeholder="Selectionner une Agence"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeAgence}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                agences && agences.length ?
                                                    agences.map((agence, k) => {
                                                        return <Option key={k} value={agence.codAgence}>{agence.nomAgence}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button onClick={this.openCategorieAdd} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>

                        </Row>

                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="puVente">Prix Vente(Ordinaire)</Label>
                                    <Input type="number" name="puVente" id="puVente" value={puVente} onChange={this.handleChange} placeholder="0" />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="prixDetaillant">Prix Détaillant</Label>
                                    <Input type="number" name="prixDetaillant" id="prixDetaillant" value={prixDetaillant} onChange={this.handleChange} placeholder="0" />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="prixGrossiste">Prix Grossiste</Label>
                                    <Input type="number" name="prixGrossiste" id="prixGrossiste" value={prixGrossiste} onChange={this.handleChange} placeholder="0" />
                                </FormGroup>
                            </Col>

                        </Row>

                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isModalVisible}
                    title="Détails Article"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="link" type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,
                        /*<Button  type="primary" loading={loading} onClick={this.handleCancel}>
                          Envoyer
                        </Button>,
                        <Button
                          key="link"
                          href="https://google.com"
                          type="primary"
                          loading={loading}
                          onClick={this.handleOk}
                        >
                          Search on Google
                        </Button>,*/
                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="codPro">Code Produit</Label>
                                    <Input disabled type="number" name="codPro" id="codPro" value={codPro} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            {/*<Col md={6}>
                <FormGroup>
                  <Label for="codInterne">Référence Article</Label>
                  <Input disabled type="text" name="codInterne" value={codInterne} id="codInterne" onChange={this.handleChange} placeholder="" />
                </FormGroup>
                </Col>*/}

                            <Col md={9}>
                                <FormGroup>
                                    <Label for="designation">Désignation</Label>
                                    <Input disabled type="text" name="designation" value={designation} onChange={this.handleChange} id="designation" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="codeCat">Catégorie</Label>
                                    <InputGroup compact>
                                        <Select
                                            disabled
                                            value={codeCat}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '85%' }}
                                            placeholder="Selectionner Catégorie"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeCategorie}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                categories && categories.length ?
                                                    categories.map((categorie, k) => {
                                                        return <Option key={k} value={categorie.codCat}>{categorie.libCat}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button disabled onClick={this.openCategorieAdd} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>

                        </Row>

                        <Row form>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>

                                            <th className="text-center">Agence</th>
                                            <th className="text-center">Prix Vente</th>
                                            <th className="text-center">Prix Détaillant</th>
                                            <th className="text-center">Prix Grossiste</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            article && article.agences && article.agences.length ?
                                                article.agences.map((agence, k) => {
                                                    //const puRevient = parseFloat(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>

                                                            <td className="text-center">{agence.nomAgence}</td>
                                                            <td className="text-center">{numberWithCommas(parseFloat(agence.prixAgence))}</td>
                                                            <td className="text-center">{numberWithCommas(parseFloat(agence.prixDetaillant))}</td>
                                                            <td className="text-center">{numberWithCommas(parseFloat(agence.prixGrossiste))}</td>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>

                        </Row>

                    </Form>
                </Modal>

                <Modal
                    width={600}
                    visible={isAddCategorieModalVisible}
                    title="Ajouter une Catégorie"
                    //onOk={this.handleAddOpen}
                    onCancel={this.openCategorieAdd}
                    footer={[
                        <Button type="primary" loading={loading} onClick={this.handleAddCategorie}>
                            Envoyer
                        </Button>,
                        <Button type="danger" onClick={this.openCategorieAdd}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            {/*<Col md={3}>
                <FormGroup>
                  <Label for="codCat">Code Catégorie</Label>
                  <Input type="number" name="codCat" id="codCat" value={codCat} onChange={this.handleChange} placeholder="" />
                </FormGroup>
        </Col>*/}
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="libCat">Libellé</Label>
                                    <Input type="text" name="libCat" value={libCat} onChange={this.handleChange} id="libCat" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>


                    </Form>
                </Modal>

            </div>
        )
    }
}


export default Prix;
